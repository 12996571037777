





























import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'Page404'
})
export default class extends Vue {
    public message = '404 找不到页面'
}
